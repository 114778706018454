<template>
  <v-container class="fill-heigt pt-0" fluid>
    <v-row class="d-flex flex-row py-1">
      <div class="text-left black--text px-3" style="margin-top:3%">
        {{ $t("test_library") }}
      </div>
      <v-spacer></v-spacer>

      <v-col cols="3" md="3" class="text-right px-0 mt-1">
        <test-filter @apply="applyTestFilter" @clean="applyTestFilter" />
      </v-col>
      <v-col cols="9" md="3">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchText')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>
    <v-row class="py-0 px-2">
      <v-col cols="12" md="9">
        <v-row class="d-flex flex-row py-4">
          <v-radio-group
            v-model="filter.type"
            row
            class="mt-0"
            @change="applyFilter()"
          >
            <v-radio :label="$t('technician')" value="technician"></v-radio>
            <v-radio
              :label="$t('psychological')"
              value="psychological"
            ></v-radio>
          </v-radio-group>
          <v-checkbox
            v-model="filter.entity"
            :label="$t('myteste')"
            class="mt-0"
            :value="currentUser.entity.id"
            @change="applyFilter()"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="text-right justify-end">
        <div class="d-flex justify-end">
          <v-col cols="12" md="3" class="text-right justify-end">
            <div class="d-flex justify-end">
              <template>
                <div style="margin-top:-1%">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="accessLevel(currentUser, 'send_test')"
                        dense
                        text
                        class="mr-2"
                        color="primary"
                        @click="dialogCandidate = true"
                      >
                        <v-icon left color="primary">mdi-send</v-icon
                        >{{ $t("send_test") }}
                      </v-btn>
                    </template>
                    <div
                      v-html="$t('tooltip_sendTest')"
                    ></div>
                  </v-tooltip>
                </div>
              </template>

              <v-btn
                v-if="accessLevel(currentUser, 'tests_create')"
                dense
                color="primary"
                @click.prevent="dialogTestAdd()"
              >
                <v-icon left>mdi-plus</v-icon>{{ $t("createnew") }}
              </v-btn>
            </div>
          </v-col>
        </div>
        <!-- <pre>
          {{filteredItems[5].id}}
        </pre> -->
      </v-col>
    </v-row>
    <infinite-scroll
      :items="filteredItems"
      :totalPages="totalPages"
      @refetch="fetch"
    >
      <template v-slot:item="{ item }">
        <!-- <pre>
          {{ item.entity }}
        </pre> -->
        <v-skeleton-loader
          v-if="$apollo.loading"
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
        <test-card
          v-else
          :test="item"
          @delete="openConfirmeDialog"
          @edit="edit"
          @preview="showPreviewDialog"
        />
      </template>
    </infinite-scroll>
    <add-candidate-test-dialog
      :dialog="dialogCandidate"
      @close="dialogCandidate = false"
    />
    <add-test-dialog />
    <edit-test-dialog />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
    />
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('testQ')"
      @yes="deleteTest"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-container>
</template>

<script>
import { DELETE_TEST_MUTATION } from "./../graphql/Mutation.service";
import { GET_TESTS_ENTITY_QUERY } from "./../graphql/Query.service";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import AddCandidateTestDialog from "./../components/AddCandidateTestDialog.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import PreviewTestDialog from "./../components/PreviewTestDialog.vue";
import TestCard from "./../components/TestCard.vue";
import TestFilter from "../../../components/TestFilter.vue";
import AddTestDialog from "../components/AddTestDialog.vue";
const EditTestDialog = () => import('../components/EditTestDialog.vue')
import Test from "@/models/Test";
import InfiniteScroll from "./../components/InfiniteScroll.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Test",
  components: {
    AddCandidateTestDialog,
    InfoViewDialog,
    TestFilter,
    TestCard,
    PreviewTestDialog,
    AddTestDialog,
    EditTestDialog,
    InfiniteScroll,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  mixins: [dialogMixins, replaceSpecialCharsMixins, accessLevelMixins],
  data: () => ({
    appliedFilter: true,
    dialog: false,
    dialogCandidate: false,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      limit: 8,
      page: 1,
      positions: undefined,
      type: "",
      user: undefined,
    },
    infoDialog: false,
    previewDialog: false,
    tests: [],
    test: new Test(),
    textSearch: undefined,
    totalPages: 1,
  }),
  apollo: {
    tests: {
      query: GET_TESTS_ENTITY_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
      fetchPolicy: 'no-cache'
    },
  },
  watch: {
    tests: function(val) {
      console.log('vendo tes',val);
      if (this.appliedFilter) {
        this.setTestsState(val.tests);
        this.appliedFilter = false;
      } else {
        let result = val
        result = result.tests.filter(test=>test.entity === null)
        result = result.tests.filter(test => test.isDraft === false)
       let  resultTest = this.setTestsState(result);
        this.setTestsScrolled(resultTest);
      }
      this.totalPages = val.totalPages;
    },
  },
//   mounted(){
// this
//   },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: "test/getTests",
    }),
    filteredItems() {
      let resultItems = [...this.getTests];
      let rootTests = resultItems.filter(test => test.creatorType === 'root');
      let partnerTests = resultItems.filter(test => test.creatorType === 'partner' && (test.publish===true||test.publish===null));
      let entityTests = resultItems.filter(test => test.creatorType === 'entity');
      resultItems = (partnerTests.concat(rootTests)).concat(entityTests);
      resultItems = resultItems.filter(test => !(test.creatorType === 'partner' && !test.status && test.reason != null ));
      // resultItems = resultItems.filter(test => test.isDraft === false);

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      return resultItems;
    },
    infoView() {
      return {
        icon: "mdi-clipboard-list-outline",
        title: this.$t("tests"),
        content: this.$t("testsinfo"),
      };
    },
  },
  created() {},
  methods: {
    ...mapActions({
      removeTestState: "test/removeTest",
      setTestsState: "test/setTests",
      setTestsScrolled: "test/setTestsScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch(1);
    },
    applyTestFilter(filter) {
      const { areas, category, positions } = filter
      this.filter = { ...this.filter, areas, category, positions };
      console.log(this.filter);
      this.applyFilter();
    },
    changePage(page) {
      this.filter.page = page;
      this.applyFilter();
    },
    cleanFilter() {
      this.appliedFilter = false;
      this.filter = {
        areas: undefined,
        category: "",
        entity: undefined,
        limit: 8,
        page: 1,
        positions: undefined,
        type: "",
        user: undefined,
      };
    },
    async deleteTest(id) {
      this.confirm = false;
      this.removeId = undefined;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TEST_MUTATION,
          variables: { id },
        });
        this.removeTestState(id);
        this.success = this.$t('test_remove');
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    dialogTestAdd() {
      this.filter.type = ""
      // eslint-disable-next-line no-undef
      Fire.$emit("clearTestFilter");
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogTestAdd");
      
    },
    edit(test) {
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogTestAddEdit", test);
    },
    async fetch(page) {
      if (page > this.totalPages) {
        return;
      }
      this.filter.page = page;
      await this.$apollo.queries.tests.refetch();
    },
    openConfirmeDialog(id) {
      console.log("Sim!");
      this.removeId = id;
      this.confirm = true;
    },
    search() {},
    showPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },
  },
};
</script>
