<template>
<div>

<div class="d-flex justify-center">
      <div class="black--text text-center" v-html="$t('send_test_title')"></div>
      <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mx-3"> mdi-help-circle-outline</v-icon>
            </template>
            <div v-html="$t('tooltip_selecttest_candidate')"></div>
          </v-tooltip>
        </template>
  </div>
<!-- <div class="d-flex justify-center" >
 <v-expand-transition> 
   <div class="grey--text text-center" >{{$t('tooltip_selecttest_list')}}<a @click="expand = !expand" v-show="!expand"><span style="color:#000">...</span> {{$t('see_more')}}</a>
            
            <span class="grey--text"  v-show="expand">{{$t('tooltip_selecttest_cont_list')}} <a @click="expand = !expand" ><span style="color:#000"></span> {{$t('see_less')}}</a></span>
            
            </div></v-expand-transition>
</div> -->
<br>
  <v-row >
    
    <v-col 
      cols="12"
      sm="6"
      md="4"
      lg="3" 
      xl="2"
      v-for="item in items" :key="item.id"
    >  
      <slot name="item" :item="item" />
    </v-col>

    <div v-if="items.length" v-observe-visibility="handleScrolledToBottom"></div>
  </v-row>
</div>
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    items: {
      required: true,
      type: Array
    },
    totalPages: Number
  },
  data: () => ({
    page: 1,
    expand: false,
  }),
  watch: {
    totalPages () {
      this.page = 1
    }
  },
  methods: {
    handleScrolledToBottom (isVisible) {
      if (!isVisible) { return }

      this.page++

      this.$emit('refetch', this.page)
    }
  }
}
</script>